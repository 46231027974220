<template>
  <div>
    <div>
      La fiche journalière est un récapitulatif des activités commerciales pour
      une journée donnée. Il contient les renseignements suivants :
      <br />
      <br />

      <div class="form-check" v-for="sheet in sheets" :key="sheet.name">
        <br />
        <input
          class="form-check-input"
          type="checkbox"
          :id="sheet.name"
          :value="sheet.name"
          v-model="selections"
        />
        <label class="form-check-label" :for="sheet.name">
          <div>
            {{ sheet.title }}
          </div>
          <div class="ms-5">
            {{ sheet.description }}
          </div>
        </label> 
        <br />
      </div>

      Cette fiche journalière est un outil essentiel pour suivre les activités
      commerciales quotidiennes, évaluer les performances de l'entreprise et
      prendre des décisions stratégiques. Elle permet de maintenir un suivi
      précis des achats, des ventes, des paiements, du crédit et du stock,
      offrant ainsi une vision globale de l'activité quotidienne de l'entreprise
    </div>
    <hr />

    <div class="row justify-content-between align-items-end">
      <div class="col-3">
        <label for="" class="labe-form">Date</label>
        <input type="date" v-model="date" class="form-control" />
      </div>
      <div class="col-auto">
        <button
          @click="downloadDailySheet(date, selections)"
          class="btn btn0 btn-secondary"
        >
          <i class="bi bi-cloud-arrow-down"></i>
          <span> Télécharger </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      selections: [],
      sheets: [
        {
          name: "daily-purchases",
          title: "1 - Achats au jour :",
          },

        {
          name: "daily-sales",
          title: "2 - Ventes au jour :",
         },

        {
          name: "daily-payments",
          title: "3 - Paiements de jour :",
         },

        {
          name: "daily-unpaids",
          title: "4 - Crédit (impayées) de jour :",
         },

        {
          name: "daily-stocks",
          title: "5 - Stock actuel à la fin de la journée :",
         },

        {
          name: "daily-inventories",
          title:
            "6 - Quantité totale d'entrée/sortie/en stock pour chaque article :",
        },

        {
          name: "daily-expenses",
          title: "7 - Dépenses au jour :",
        },

        {
          name: "daily-checks",
          title: "8 - Chéques au jour :",
        },

        {
          name: "daily-transactions",
          title: "9 - Transactions au jour :",
       },

        {
          name: "daily-discounts",
          title: "10 - Remises au jour :",
       },
      ],
    };
  },
  methods: {
    downloadDailySheet(date, selections) {
      this.$store.dispatch("statistic/downloadDailySheet", {
        selections,
        date,
      });
    },
  },
};
</script>
